<template>
  <div class="container">
    <v-card class="product-development">
      <h1>SEAMLESS SOURCE</h1>
      <h2>Style Cost Sheet</h2>
      <md-table>
        <md-table-row>
          <md-table-head id="main-table-head">Style Number</md-table-head>
          <md-table-cell>{{ model.styleNumber }}</md-table-cell>
          <md-table-head id="main-table-head">Brand Name</md-table-head>
          <md-table-cell>{{ model.brandName }}</md-table-cell>
        </md-table-row>
        <md-table-row>
          <md-table-head id="main-table-head">Style Name</md-table-head>
          <md-table-cell>
            <input
              placeholder="Add style name"
              type="text"
              v-model="model.styleName"
              @input="checkDataIsUpdated()"
            />
          </md-table-cell>

          <md-table-head id="main-table-head">Currency</md-table-head>
          <md-table-cell>
            <md-select
              name="currency"
              v-model="model.currency"
              @click="currencyDisable = false"
              @input="checkCurrencyIsUpdated()"
            >
              <md-option
                class="list_color"
                v-for="currency in currencies"
                :key="'currency-' + currency.currency_code"
                :value="currency.currency_code"
                >{{ currency.display_name }}
              </md-option>
            </md-select>
          </md-table-cell>
        </md-table-row>

        <md-table-row>
          <md-table-head id="main-table-head">Brand Code</md-table-head>
          <md-table-cell>{{ model.brandCode }}</md-table-cell>
          <md-table-head id="main-table-head">Profit / Lost</md-table-head>
          <md-table-cell>
            <span style="margin-right: 1px">{{ getCurrencySymbol }}</span>
            {{
              isNaN(parseFloat(totalProfitValue))
                ? 0
                : parseFloat(totalProfitValue).toFixed(2)
            }}
          </md-table-cell>
        </md-table-row>
      </md-table>
    </v-card>

    <v-row>
      <v-col v-if="loading" cols="7">
        <span class="text-center" style="justify-content: right; display: flex">
          <md-progress-spinner
            class="color_stroke"
            md-mode="indeterminate"
          ></md-progress-spinner>
        </span>
      </v-col>
      <v-col v-else cols="12" align="right">
        <div class="version-container" v-if="versions.length > 1">
          <md-field class="field_size">
            <label for="versions">Version</label>
            <md-select
              v-model="selectedVersion"
              name="version"
              id="version"
              @input="getCostSheetVersionData()"
            >
              <md-option
                class="list_color"
                v-for="version in versions"
                :key="'version-' + version"
                :value="version"
                >{{
                  version != "Current Version"
                    ? version + "v"
                    : currentVersion + "v" + " (Latest)"
                }}
              </md-option>
            </md-select>
          </md-field>
        </div>
        <br />
        <div
          class="btn-align"
          v-if="selectedVersion != 'Current Version' && versions.length > 1"
        >
          <v-btn depressed class="make-version" @click="makeCurrentVersion">
            Make This Version as Current Version
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-card class="product-development">
      <h2>Product Development Costing</h2>
      <table>
        <tr>
          <th id="th1-par"></th>
          <th id="th1-cus-head">Income Customers</th>
          <th id="th1">Suppliers</th>
          <th id="th1-cus-head">Payment</th>
          <th id="th1-cus-head">Profit / Lost</th>
        </tr>

        <tr
          v-for="(costData, index) in costServiceData"
          :key="costData.display_name"
        >
          <th id="th1-par">{{ costData.display_name }}</th>
          <td id="th1-cus">
            <div style="display: flex">
              <span style="margin-right: 1px">{{ getCurrencySymbol }}</span>
              <input
                type="number"
                v-on:keypress="inputNumbersOnly"
                v-model="costData.income"
                placeholder="0.00"
                step=".01"
                @input="checkDataIsUpdated()"
              />
            </div>
          </td>
          <td>
            <div>
              <v-autocomplete
                dense
                :items="JSON.parse(JSON.stringify(model.allSuppliers))"
                item-text="name"
                item-value="id"
                :loading="!supplierData[index].loaded"
                label="Select Suppliers"
                multiple
                return-object
                @change="saveSuppliers(index)"
                :menu-props="{
                  closeOnContentClick:
                    !model.allSuppliers || !model.allSuppliers.length,
                }"
                :disabled="!isAccountVerified"
                v-model="supplierData[index].suppliers"
              >
                <template v-slot:no-data>
                  <div class="no-suppliers-text" @click="changeTab">
                    No suppliers. Click here to assign.
                  </div>
                </template>
              </v-autocomplete>
              <div v-if="!isAccountVerified" class="not-verified-text">
                Your company profile needs to be verified to select a supplier
                or factory.
              </div>
            </div>
            <div>
              <table
                v-if="
                  supplierData[index] &&
                  supplierData[index].hasOwnProperty('suppliers')
                "
              >
                <tr
                  v-for="(selectedSupplier, supplierIndex) in JSON.parse(
                    JSON.stringify(supplierData[index].suppliers)
                  )"
                  :key="selectedSupplier.id"
                >
                  <td>
                    <div>
                      {{ selectedSupplier.name }}
                    </div>
                  </td>
                  <td>
                    <v-autocomplete
                      dense
                      :items="selectedSupplier.factories"
                      item-text="name"
                      item-value="id"
                      no-data-text="No factories left to assign"
                      label="Select Factories"
                      multiple
                      @blur="saveFactories(index, selectedSupplier.id)"
                      v-model="
                        supplierData[index].suppliers[supplierIndex]
                          .selectedFactories
                      "
                      :disabled="!isAccountVerified"
                    ></v-autocomplete>
                    <div
                      class="mb-2"
                      v-for="selectedFactory in selectedSupplier.selectedFactories"
                      :key="selectedFactory"
                    >
                      {{
                        selectedSupplier.factories.find(
                          (factory) => factory.id == selectedFactory
                        ).name
                      }}
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </td>
          <td id="th1-cus">
            <div style="display: flex">
              <span style="margin-right: 1px">{{ getCurrencySymbol }}</span>
              <input
                type="number"
                v-on:keypress="inputNumbersOnly"
                v-model="costData.payment"
                step=".01"
                placeholder="0.00"
                @input="checkDataIsUpdated()"
              />
            </div>
          </td>
          <td id="th1-cus">
            <span style="margin-right: 1px">{{ getCurrencySymbol }}</span>
            {{
              isNaN(parseFloat(costData.income - costData.payment))
                ? 0
                : parseFloat(costData.income - costData.payment).toFixed(2)
            }}
          </td>
        </tr>

        <tr>
          <th id="th1-par">Total Profit / Lost</th>
          <td id="th1-cus-head-2"></td>
          <td id="th1"></td>
          <td id="profit-payment">
            <span style="margin-right: 1px">{{ getCurrencySymbol }}</span>
            {{ totalPaymentValue }}
          </td>
          <td id="profit-payment">
            <span style="margin-right: 1px">{{ getCurrencySymbol }}</span>
            {{ totalProfitValue }}
          </td>
        </tr>
      </table>

      <v-divider></v-divider>
    </v-card>
    <div v-if="model.comment">
      <div style="margin-top: 40px; font-weight: bold; text-align: left">
        Comment
      </div>
      <div class="comment-container">{{ model.comment }}</div>
    </div>
    <div>
      <div class="btn-align">
        <v-btn
          v-if="newCostSheet"
          class="save_button_color"
          :disabled="!saving || initialDisable"
          @click="saveServiceData"
          >Save
        </v-btn>
        <v-btn
          v-else
          class="save_button_color"
          :disabled="!saving || initialDisable"
          @click="userComment = true"
          >Save
        </v-btn>
      </div>
    </div>

    <v-dialog max-width="500" v-model="userComment">
      <v-card>
        <v-card-title>
          <p class="display-2 black--text underline text-left">
            Provide the reason for updating
          </p>
        </v-card-title>
        <v-divider />
        <v-card-text height="1000px" class="pt-0">
          <v-container class="py-0">
            <v-form ref="addComment" class="mt-5">
              <v-row>
                <v-col cols="12" md="12">
                  <p
                    class="text-left mb-1 ml-0 formLabel font-weight-light grey--text text--darken-1"
                  >
                    Add your comment here
                    <span class="required-field">*</span>
                  </p>
                  <v-textarea
                    v-model="model.newComment"
                    outlined
                    dense
                    color="purple darken-3"
                    maxLength="5000"
                    :rules="rules.commentRules"
                  >
                  </v-textarea>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <md-button
            class="md-raised md-primary"
            style="background-color: #582963; color: #eef0fb"
            @click="userCommentClose"
            >Close
          </md-button>
          <md-button
            class="md-raised md-primary"
            style="background-color: #582963; color: #eef0fb"
            @click="saveServiceData"
            >Submit
          </md-button>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog" persistent max-width="290">
      <v-card>
        <v-card-text
          >Please note, currency values will not be converted</v-card-text
        >
        <v-card-actions>
          <v-btn color="success" text="center" @click="btnDialogCancel">
            Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" text="center" @click="btnDialogOk"> Ok </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog max-width="500" v-model="secondaryCommentWindow">
      <v-card>
        <v-card-title>
          <p class="display-2 black--text underline text-left">
            Provide the reason for updating
          </p>
        </v-card-title>
        <v-divider />
        <v-card-text height="1000px" class="pt-0">
          <v-container class="py-0">
            <v-form ref="secondaryCostComment" class="mt-5">
              <v-row>
                <v-col cols="12" md="12">
                  <p
                    class="text-left mb-1 ml-0 formLabel font-weight-light grey--text text--darken-1"
                  >
                    Add your comment here
                    <span class="required-field">*</span>
                  </p>
                  <v-textarea
                    v-model="secondaryCostComment"
                    outlined
                    dense
                    color="purple darken-3"
                    maxLength="5000"
                    :rules="rules.secondaryCommentRules"
                  >
                  </v-textarea>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <md-button
            class="md-raised md-primary"
            style="background-color: #582963; color: #eef0fb"
            @click="secondaryCommentClose"
            >Close
          </md-button>
          <md-button
            class="md-raised md-primary"
            style="background-color: #582963; color: #eef0fb"
            @click="saveSecondaryComment"
            >Submit
          </md-button>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div v-if="secondaryCostLoading" class="costing-table-container">
      <v-row>
        <v-col cols="7">
          <span
            class="text-center"
            style="justify-content: right; display: flex"
          >
            <md-progress-spinner
              class="color_stroke"
              md-mode="indeterminate"
            ></md-progress-spinner>
          </span>
        </v-col>
      </v-row>
    </div>
    <div
      class="costing-table-container"
      :style="secondaryCostLoading ? 'display: none;' : null"
    >
      <v-row>
        <v-col cols="12" align="right">
          <div
            class="version-container"
            v-if="secondaryCostVersions.length > 1"
          >
            <md-field class="field_size">
              <label for="secondary-version">Version</label>
              <md-select
                v-model="selectedSecondaryVersion"
                name="secondary-version"
                id="secondary-version"
                @input="getSecondaryVersionsData()"
              >
                <md-option
                  class="list_color"
                  v-for="secondaryVersion in secondaryCostVersions"
                  :key="'secondary-version-' + secondaryVersion"
                  :value="secondaryVersion"
                >
                  {{
                    secondaryVersion +
                    "v" +
                    (secondaryVersion == secondaryCostVersions[0]
                      ? " (Latest)"
                      : "")
                  }}
                </md-option>
              </md-select>
            </md-field>
          </div>
          <div
            class="btn-align mt-3 mb-3"
            v-if="
              selectedSecondaryVersion != secondaryCostVersions[0] &&
              secondaryCostVersions.length > 1
            "
          >
            <v-btn
              depressed
              class="make-version"
              @click="makeSecondaryCurrentVersion"
            >
              Make This Version as Current Version
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <div class="filter-container">
        <div class="filter-item">
          <input
            v-model="costTableFilter"
            type="radio"
            name="costFilter"
            id="cm"
            value="cm"
          />
          <label for="cm" class="filter-label">CM</label>
        </div>
        <div class="filter-item">
          <input
            v-model="costTableFilter"
            type="radio"
            name="costFilter"
            id="cmt"
            value="cmt"
          />
          <label for="cmt" class="filter-label">CMT</label>
        </div>
        <div class="filter-item">
          <input
            v-model="costTableFilter"
            type="radio"
            name="costFilter"
            id="fob"
            value="fob"
          />
          <label for="fob" class="filter-label">FOB</label>
        </div>
      </div>
      <h2>Cost</h2>
      <table :style="costTableFilter === 'fob' ? 'display:none;' : null">
        <customizable-table-body
          title="Fabric/yarn"
          :headers="costHeaders.fabricYarn"
          :data="costRecords.fabricYarn"
          @input-data="editRecords('fabricYarn', ...arguments)"
          :suppliers="model.assignedSuppliers"
          :measurements="unitOfMeasurements"
          :isAccountVerified="isAccountVerified"
          @change-tab="changeTab"
        ></customizable-table-body>
      </table>

      <table :style="costTableFilter === 'fob' ? 'display:none;' : null">
        <customizable-table-body
          title="Fabric cost"
          :headers="costHeaders.fabricCost"
          :data="costRecords.fabricCost"
          formula="(parseFloat(column[3]) * parseFloat(column[4]) * (1 + ((parseFloat(column[5]) ? parseFloat(column[5]) : 0) + (parseFloat(column[6]) ? parseFloat(column[6]) : 0) + (parseFloat(column[7]) ? parseFloat(column[7]) : 0)) / 100)).toFixed(2)"
          setTo="column[8]"
          @input-data="editRecords('fabricCost', ...arguments)"
          :measurements="unitOfMeasurements"
          :isAccountVerified="isAccountVerified"
        >
          <tr class="total-td">
            <td
              :colspan="costHeaders.fabricCost.length - 1"
              class="disabled-td"
            >
              Total fabric cost
            </td>
            <td class="disabled-td">
              <input
                type="text"
                :value="totalFabricCost"
                disabled
                placeholder="0.00"
              />
            </td>
          </tr>
        </customizable-table-body>
      </table>

      <table :style="costTableFilter === 'fob' ? 'display:none;' : null">
        <customizable-table-body
          title="Trim cost"
          subTitle="Sewing trims-"
          :headers="costHeaders.trimCost"
          :data="costRecords.sewingTrims"
          formula="(parseFloat(column[3]) * parseFloat(column[4]) * (1 + ((parseFloat(column[5]) ? parseFloat(column[5]) : 0) + (parseFloat(column[6]) ? parseFloat(column[6]) : 0) + (parseFloat(column[7]) ? parseFloat(column[7]) : 0)) / 100)).toFixed(2)"
          setTo="column[8]"
          @input-data="editRecords('sewingTrims', ...arguments)"
          :suppliers="model.assignedSuppliers"
          :measurements="unitOfMeasurements"
          :isAccountVerified="isAccountVerified"
          @change-tab="changeTab"
        >
          <tr>
            <td :colspan="costHeaders.trimCost.length - 1" class="disabled-td">
              Total sewing trim cost
            </td>
            <td class="disabled-td">
              <input
                type="text"
                :value="totalSewingTrimCost"
                disabled
                placeholder="0.00"
              />
            </td>
          </tr>
        </customizable-table-body>
        <customizable-table-body
          class="packing-trims"
          subTitle="Packing trims-"
          :headers="costHeaders.trimCost"
          :displayHeaders="false"
          :data="costRecords.packingTrims"
          formula="(parseFloat(column[3]) * parseFloat(column[4]) * (1 + ((parseFloat(column[5]) ? parseFloat(column[5]) : 0) + (parseFloat(column[6]) ? parseFloat(column[6]) : 0) + (parseFloat(column[7]) ? parseFloat(column[7]) : 0)) / 100)).toFixed(2)"
          setTo="column[8]"
          @input-data="editRecords('packingTrims', ...arguments)"
          :suppliers="model.assignedSuppliers"
          :measurements="unitOfMeasurements"
          :isAccountVerified="isAccountVerified"
          @change-tab="changeTab"
        >
          <tr class="total-td">
            <td :colspan="costHeaders.trimCost.length - 1" class="disabled-td">
              Total packing trim cost
            </td>
            <td class="disabled-td">{{ totalPackingTrimCost }}</td>
          </tr>
          <tr class="total-td">
            <td :colspan="costHeaders.trimCost.length - 1" class="disabled-td">
              Total trim cost
            </td>
            <td class="disabled-td">
              <input
                type="text"
                :value="totalTrimCost"
                disabled
                placeholder="0.00"
              />
            </td>
          </tr>
        </customizable-table-body>
      </table>

      <table :style="costTableFilter === 'fob' ? 'display:none;' : null">
        <customizable-table-body
          title="Embellishment charges"
          :headers="costHeaders.embellishmentCharges"
          :data="costRecords.embellishmentCharges"
          formula="(parseFloat(column[1]) * (1 + ((parseFloat(column[2]) ? parseFloat(column[2]) : 0) + (parseFloat(column[3]) ? parseFloat(column[3]) : 0))/100)).toFixed(2)"
          setTo="column[4]"
          :isAccountVerified="isAccountVerified"
          @input-data="editRecords('embellishmentCharges', ...arguments)"
        >
          <tr class="total-td">
            <td
              :colspan="costHeaders.embellishmentCharges.length - 1"
              class="disabled-td"
            >
              Total embellishment charges cost
            </td>
            <td class="disabled-td">
              <input
                type="text"
                :value="totalEmbellishmentCost"
                disabled
                placeholder="0.00"
              />
            </td>
          </tr>
        </customizable-table-body>
      </table>

      <table>
        <customizable-table-body
          :headers="costHeaders.cmFOBCharges"
          :data="costRecords.cmFOBCharges"
          :isAccountVerified="isAccountVerified"
          @input-data="editRecords('cmFOBCharges', ...arguments)"
        >
        </customizable-table-body>
      </table>

      <table>
        <customizable-table-body
          title="Other cost"
          :headers="costHeaders.otherCost"
          :data="costRecords.otherCost"
          formula="(parseFloat(column[1]) * parseFloat(column[2])).toFixed(2)"
          setTo="column[3]"
          :isAccountVerified="isAccountVerified"
          @input-data="editRecords('otherCost', ...arguments)"
        >
          <tr class="total-td">
            <td :colspan="costHeaders.otherCost.length - 1" class="disabled-td">
              Total other charges cost
            </td>
            <td class="disabled-td">
              <input
                type="text"
                :value="totalOtherCost"
                disabled
                placeholder="0.00"
              />
            </td>
          </tr>
        </customizable-table-body>
      </table>

      <table>
        <tbody>
          <tr>
            <td :colspan="2" class="subtitle"></td>
            <td class="subtitle">Per piece total costs</td>
            <td class="subtitle">Total planned costs</td>
            <td class="subtitle">Actual Cost</td>
            <td class="subtitle">Profit / Loss</td>
          </tr>
          <tr>
            <td :colspan="2" class="disabled-td">Total fabric cost</td>
            <td class="disabled-td">{{ totalFabricCost }}</td>
            <td class="disabled-td">
              {{
                totalFabricCost && bulkTotal
                  ? parseFloat(totalFabricCost * bulkTotal).toFixed(2)
                  : null
              }}
            </td>
            <td>
              <input
                style="min-width: 0px; width: 100%"
                v-model="costRecords.fabricActualCost"
                type="number"
                step=".01"
                v-on:keypress="inputNumbersOnly"
                placeholder="0.00"
                pattern="^[-]?[0-9]*[.]?[0-9]+"
                oninput="
                  if (!/^\-?[0-9]*\.?[0-9]*$/.test(this.value)) {
                    this.value = this.value.replace(/[^\d.-]/g, '');
                  }
                "
              />
            </td>
            <td class="disabled-td">
              {{
                costRecords.fabricActualCost
                  ? (
                      parseFloat(totalFabricCost * bulkTotal) -
                      costRecords.fabricActualCost
                    ).toFixed(2)
                  : null
              }}
            </td>
          </tr>
          <tr>
            <td :colspan="2" class="disabled-td">Total trim cost</td>
            <td class="disabled-td">{{ totalTrimCost }}</td>
            <td class="disabled-td">
              {{
                totalTrimCost && bulkTotal
                  ? parseFloat(totalTrimCost * bulkTotal).toFixed(2)
                  : null
              }}
            </td>
            <td>
              <input
                style="min-width: 0px; width: 100%"
                v-model="costRecords.trimActualCost"
                type="number"
                step=".01"
                v-on:keypress="inputNumbersOnly"
                pattern="^-?[0-9]*[.]?[0-9]+"
                placeholder="0.00"
                oninput="
                  if (!/^\-?[0-9]*\.?[0-9]*$/.test(this.value)) {
                    this.value = this.value.replace(/[^\d.-]/g, '');
                  }
                "
              />
            </td>
            <td class="disabled-td">
              {{
                costRecords.trimActualCost
                  ? (
                      parseFloat(totalTrimCost * bulkTotal) -
                      costRecords.trimActualCost
                    ).toFixed(2)
                  : null
              }}
            </td>
          </tr>
          <tr>
            <td :colspan="2" class="disabled-td">
              Total embellishment charges cost
            </td>
            <td class="disabled-td">{{ totalEmbellishmentCost }}</td>
            <td class="disabled-td">
              {{
                totalEmbellishmentCost && bulkTotal
                  ? parseFloat(totalEmbellishmentCost * bulkTotal).toFixed(2)
                  : null
              }}
            </td>
            <td>
              <input
                style="min-width: 0px; width: 100%"
                v-model="costRecords.embellishmentActualCost"
                type="number"
                step=".01"
                v-on:keypress="inputNumbersOnly"
                placeholder="0.00"
                pattern="^-?[0-9]*[.]?[0-9]+"
                oninput="
                  if (!/^\-?[0-9]*\.?[0-9]*$/.test(this.value)) {
                    this.value = this.value.replace(/[^\d.-]/g, '');
                  }
                "
              />
            </td>
            <td class="disabled-td">
              {{
                costRecords.embellishmentActualCost
                  ? (
                      parseFloat(totalEmbellishmentCost * bulkTotal) -
                      costRecords.embellishmentActualCost
                    ).toFixed(2)
                  : null
              }}
            </td>
          </tr>
          <tr>
            <td :colspan="2" class="disabled-td">CM/FOB charges</td>
            <td class="disabled-td">{{ totalcmFOBCharges }}</td>
            <td class="disabled-td">
              {{
                totalcmFOBCharges && bulkTotal
                  ? parseFloat(totalcmFOBCharges * bulkTotal).toFixed(2)
                  : null
              }}
            </td>
            <td>
              <input
                style="min-width: 0px; width: 100%"
                v-model="costRecords.cmFobActualCost"
                type="number"
                step=".01"
                pattern="^-?[0-9]*[.]?[0-9]+"
                v-on:keypress="inputNumbersOnly"
                placeholder="0.00"
                oninput="
                  if (!/^\-?[0-9]*\.?[0-9]*$/.test(this.value)) {
                    this.value = this.value.replace(/[^\d.-]/g, '');
                  }
                "
              />
            </td>
            <td class="disabled-td">
              {{
                costRecords.cmFobActualCost
                  ? (
                      parseFloat(totalcmFOBCharges * bulkTotal) -
                      costRecords.cmFobActualCost
                    ).toFixed(2)
                  : null
              }}
            </td>
          </tr>
          <tr>
            <td :colspan="2" class="disabled-td">Total other charges cost</td>
            <td class="disabled-td">{{ totalOtherCost }}</td>
            <td class="disabled-td">
              {{
                totalOtherCost && bulkTotal
                  ? parseFloat(totalOtherCost * bulkTotal).toFixed(2)
                  : null
              }}
            </td>
            <td>
              <input
                style="min-width: 0px; width: 100%"
                v-model="costRecords.otherActualCost"
                type="number"
                step=".01"
                v-on:keypress="inputNumbersOnly"
                pattern="^-?[0-9]*[.]?[0-9]+"
                placeholder="0.00"
                oninput="
                  if (!/^\-?[0-9]*\.?[0-9]*$/.test(this.value)) {
                    this.value = this.value.replace(/[^\d.-]/g, '');
                  }
                "
              />
            </td>
            <td class="disabled-td">
              {{
                costRecords.otherActualCost
                  ? (
                      parseFloat(totalOtherCost * bulkTotal) -
                      costRecords.otherActualCost
                    ).toFixed(2)
                  : null
              }}
            </td>
          </tr>
          <tr>
            <td :colspan="2" class="disabled-td">Over head Cost</td>
            <td>
              <input
                style="min-width: 0px; width: 100%"
                v-model="costRecords.overHeadValue"
                type="number"
                step=".01"
                placeholder="0.00"
                pattern="^-?[0-9]*[.]?[0-9]+"
                oninput="
                  if (!/^\-?[0-9]*\.?[0-9]*$/.test(this.value)) {
                    this.value = this.value.replace(/[^\d.-]/g, '');
                  }
                "
                v-on:keypress="inputNumbersOnly"
              />
            </td>
            <td class="disabled-td">
              {{
                costRecords.overHeadValue && bulkTotal
                  ? parseFloat(costRecords.overHeadValue * bulkTotal).toFixed(2)
                  : null
              }}
            </td>
            <td>
              <input
                style="min-width: 0px; width: 100%"
                v-model="costRecords.overHeadActualCost"
                type="number"
                step=".01"
                pattern="^-?[0-9]*[.]?[0-9]+"
                placeholder="0.00"
                oninput="
                  if (!/^\-?[0-9]*\.?[0-9]*$/.test(this.value)) {
                    this.value = this.value.replace(/[^\d.-]/g, '');
                  }
                "
                v-on:keypress="inputNumbersOnly"
              />
            </td>
            <td class="disabled-td">
              {{
                costRecords.overHeadActualCost
                  ? (
                      parseFloat(costRecords.overHeadValue * bulkTotal) -
                      costRecords.overHeadActualCost
                    ).toFixed(2)
                  : null
              }}
            </td>
          </tr>
          <tr>
            <td :colspan="2" class="disabled-td">Net FOB Cost</td>
            <td class="disabled-td">{{ pieceNetFOB }}</td>
            <td class="disabled-td">
              {{
                pieceNetFOB && bulkTotal
                  ? parseFloat(pieceNetFOB * bulkTotal).toFixed(2)
                  : null
              }}
            </td>
            <td class="disabled-td">{{ pieceNetFOBActualCost }}</td>
            <td class="disabled-td">
              {{
                pieceNetFOBActualCost
                  ? (
                      parseFloat(pieceNetFOB * bulkTotal) -
                      pieceNetFOBActualCost
                    ).toFixed(2)
                  : null
              }}
            </td>
          </tr>
          <tr>
            <td class="disabled-td">Profit margin</td>
            <td>
              <div style="display: flex">
                <input
                  style="min-width: 0px; width: 100%"
                  v-model="costRecords.profitMarginPercentage"
                  type="number"
                  step=".01"
                  v-on:keypress="inputNumbersOnly"
                  pattern="^-?[0-9]*[.]?[0-9]+"
                  placeholder="0.00"
                  oninput="
                    if (!/^\-?[0-9]*\.?[0-9]*$/.test(this.value)) {
                      this.value = this.value.replace(/[^\d.-]/g, '');
                    }
                  "
                /><span>%</span>
              </div>
            </td>
            <td class="disabled-td">
              {{ pieceProfitMargin ? pieceProfitMargin : "0.00" }}
            </td>
            <td class="disabled-td">
              {{
                bulkTotal
                  ? parseFloat(
                      (pieceProfitMargin ? pieceProfitMargin : 0) * bulkTotal
                    ).toFixed(2)
                  : null
              }}
            </td>
            <td class="disabled-td">
              {{
                bulkTotal
                  ? parseFloat(
                      (pieceProfitMargin ? pieceProfitMargin : 0) * bulkTotal
                    ).toFixed(2)
                  : null
              }}
            </td>
            <td class="disabled-td">
              {{
                (
                  parseFloat(
                    (pieceProfitMargin ? pieceProfitMargin : 0) * bulkTotal
                  ) +
                  parseFloat(pieceNetFOB * bulkTotal - pieceNetFOBActualCost)
                ).toFixed(2)
              }}
            </td>
          </tr>
          <tr>
            <td :colspan="2" class="disabled-td">Final FOB US $</td>
            <td class="disabled-td">{{ pieceFinalFOBUS }}</td>
            <td class="disabled-td">
              {{
                pieceFinalFOBUS && bulkTotal
                  ? parseFloat(pieceFinalFOBUS * bulkTotal).toFixed(2)
                  : null
              }}
            </td>
            <td class="disabled-td">
              {{
                pieceNetFOBActualCost
                  ? bulkTotal
                    ? (
                        parseFloat(pieceNetFOBActualCost) +
                        parseFloat(
                          (pieceProfitMargin ? pieceProfitMargin : 0) *
                            bulkTotal
                        )
                      ).toFixed(2)
                    : parseFloat(pieceNetFOBActualCost).toFixed(2)
                  : null
              }}
            </td>
          </tr>
          <tr>
            <td :colspan="2" class="disabled-td">
              Google conversion rate to date
            </td>
            <td>
              <input
                style="min-width: 0px; width: 100%"
                v-model="costRecords.conversionRateTodate"
                type="number"
                step=".01"
                v-on:keypress="inputNumbersOnly"
                placeholder="0.00"
                pattern="^-?[0-9]*[.]?[0-9]+"
                oninput="
                  if (!/^\-?[0-9]*\.?[0-9]*$/.test(this.value)) {
                    this.value = this.value.replace(/[^\d.-]/g, '');
                  }
                "
              />
            </td>
            <td class="disabled-td">{{ costRecords.conversionRateTodate }}</td>
            <td class="disabled-td">{{ costRecords.conversionRateTodate }}</td>
          </tr>
          <tr>
            <td :colspan="2" class="disabled-td">Final FOB GBP</td>
            <td class="disabled-td">{{ pieceFinalFOBGBP }}</td>
            <td class="disabled-td">
              {{
                pieceFinalFOBUS && bulkTotal
                  ? (
                      parseFloat(pieceFinalFOBUS * bulkTotal) *
                      (parseFloat(costRecords.conversionRateTodate || 0) + 0.06)
                    ).toFixed(2)
                  : null
              }}
            </td>
            <td class="disabled-td">
              {{
                pieceNetFOBActualCost
                  ? (
                      (bulkTotal
                        ? (
                            parseFloat(pieceNetFOBActualCost) +
                            parseFloat(
                              (pieceProfitMargin ? pieceProfitMargin : 0) *
                                bulkTotal
                            )
                          ).toFixed(2)
                        : parseFloat(pieceNetFOBActualCost)) *
                      (parseFloat(costRecords.conversionRateTodate || 0) + 0.06)
                    ).toFixed(2)
                  : null
              }}
            </td>
          </tr>
        </tbody>
      </table>
      <div v-if="displayingSecondoryComment">
        <div style="margin-top: 40px; font-weight: bold; text-align: left">
          Comment
        </div>
        <div class="comment-container">{{ displayingSecondoryComment }}</div>
      </div>
      <div class="btn-align">
        <v-btn class="save_button_color" @click="saveSecondaryComment"
          >Save
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable @typescript-eslint/camelcase */

import restAdapter from "@/restAdapter";
import notification from "@/notification";
import auth from "@/auth";
import CustomizableTableBody from "../base/CustomizableTableBody.vue";
import { round } from "lodash";
import routes from "../../router/routes";

export default {
  components: { CustomizableTableBody },
  watch: {
    "$store.state.inquirySuppliersUpdated": {
      handler: function () {
        this.getSupplierDetails(this.inquiryId).then((response) => {
          this.setSuppliers(response);
        });
      },
      deep: true,
    },
  },
  data() {
    return {
      costTableFilter: "cm",
      costHeaders: {
        fabricYarn: [
          { name: "Placement of the Fabric Used" },
          { name: "Description" },
          { name: "Unit of measurement", type: "measurement" },
          { name: "Width in centimeters", type: "number" },
          { name: "Total %", type: "number" },
          { name: "Gsm", type: "number" },
          { name: "Fab Supplier", type: "suppliers" },
        ],
        fabricCost: [
          { name: "Item" },
          { name: "Type" },
          { name: "Unit of measurement", type: "measurement" },
          { name: "Consumption", type: "number" },
          { name: "Unit Price", type: "number" },
          { name: "Wastage %", type: "number", percentage: true },
          { name: "Insurance / Freight", type: "number", percentage: true },
          { name: "Finance %", type: "number", percentage: true },
          { name: "Amount", type: "disabled" },
        ],
        trimCost: [
          { name: "Item" },
          { name: "Supplier", type: "suppliers" },
          { name: "Unit of measurement", type: "measurement" },
          { name: "Consumption", type: "number" },
          { name: "Unit price", type: "number" },
          { name: "Wastage %", type: "number", percentage: true },
          { name: "Insurance / Freight", type: "number", percentage: true },
          { name: "Finance %", type: "number", percentage: true },
          { name: "Amount", type: "disabled" },
        ],
        embellishmentCharges: [
          { name: "Item" },
          { name: "Unit price", type: "number" },
          { name: "Wastage %", type: "number", percentage: true },
          { name: "Finance %", type: "number", percentage: true },
          { name: "Amount", type: "disabled" },
        ],
        cmFOBCharges: [
          { name: "CM/FOB charges" },
          { name: "Amount", type: "number" },
        ],
        otherCost: [
          { name: "Item" },
          { name: "Cost (US $)", type: "number" },
          { name: "Number of pieces", type: "number", step: "1", int: true },
          { name: "Amount", type: "disabled" },
        ],
      },
      costRecords: {
        fabricYarn: [],
        fabricCost: [],
        sewingTrims: [],
        packingTrims: [],
        embellishmentCharges: [],
        cmFOBCharges: [],
        otherCost: [],
        overHeadValue: null,
        fabricActualCost: null,
        trimActualCost: null,
        embellishmentActualCost: null,
        cmFobActualCost: null,
        otherActualCost: null,
        overHeadActualCost: null,
        profitMarginPercentage: null,
        conversionRateTodate: null,
      },
      secondaryCostRecorsExist: false,
      secondaryCostComment: null,
      secondaryCommentWindow: false,
      unitOfMeasurements: [],
      costKeys: {
        fabricYarn: [
          "placement",
          "description",
          "unit",
          "width",
          "total_percentage",
          "gsm",
          "fab_suppliers",
        ],
        fabricCost: [
          "item",
          "type",
          "unit",
          "consumption",
          "unit_price",
          "wastage",
          "insurance",
          "finance",
          "amount",
        ],
        trimCost: [
          "item",
          "supplier",
          "unit",
          "consumption",
          "unit_price",
          "wastage",
          "insurance",
          "finance",
          "amount",
        ],
        embellishmentCharges: [
          "item",
          "unit_price",
          "wastage",
          "finance",
          "amount",
        ],
        cmFOBCharges: ["item", "amount"],
        otherCost: ["item", "cost", "number_of_pieces", "amount"],
      },
      loading: false,
      secondaryCostLoading: true,
      selectedVersion: "Current Version",
      selectedSecondaryVersion: "Current Version",
      displayingSecondoryComment: null,
      currentVersion: 1,
      secondaryCurrentVersion: 1,
      versions: [],
      secondaryCostVersions: [],
      inquiryId: "",
      bulkTotal: null,
      serviceId: "",
      saving: false,
      initialDisable: true,
      currencyDisable: true,
      currencies: [],
      userComment: false,
      newCostSheet: false,
      inquiryCostServiceId: [],
      supplierLoading: true,
      costServiceData: [],
      supplierData: [],
      dialog: false,
      updatedCurrency: null,
      rules: {
        commentRules: [(value) => !!value || "Comment is required"],
        secondaryCommentRules: [(value) => !!value || "Comment is required"],
      },

      model: {
        styleNumber: "",
        styleName: "",
        brandCode: "",
        brandName: "",
        currency: "",
        profitLost: null,
        totalProfit: null,
        profitLossStatus: 1,
        comment: "",
        newComment: null,
        approvedSuppliers: [],
        assignedSuppliers: [],
        addedSuppliers: [],
        allSuppliers: [],
      },
    };
  },

  methods: {
    changeTab() {
      this.$emit("change-tab", "suppliers");
    },
    roundOf(value) {
      return round(parseFloat(value), 2);
    },
    editRecords(variable, data) {
      switch (variable) {
        case "fabricYarn":
          this.costRecords.fabricYarn = data.map((item) => item);
          break;
        case "fabricCost":
          this.costRecords.fabricCost = data.map((item) => item);
          break;
        case "sewingTrims":
          this.costRecords.sewingTrims = data.map((item) => item);
          break;
        case "packingTrims":
          this.costRecords.packingTrims = data.map((item) => item);
          break;
        case "embellishmentCharges":
          this.costRecords.embellishmentCharges = data.map((item) => item);
          break;
        case "cmFOBCharges":
          this.costRecords.cmFOBCharges = data.map((item) => item);
          break;
        case "otherCost":
          this.costRecords.otherCost = data.map((item) => item);
          break;
      }
    },

    getSecondaryCost() {
      return restAdapter.get(`/api/cost/${this.inquiryId}/secondary_cost`);
    },

    setSecondaryCost(costData) {
      this.costRecords.fabricYarn = costData.yarn.map((record) => {
        return [
          record.placement,
          record.description,
          record.unit,
          this.roundOf(record.width),
          this.roundOf(record.total_percentage),
          this.roundOf(record.gsm),
          JSON.parse(record.fab_suppliers)?.map((supplier) => {
            const supplierData = this.model.assignedSuppliers.find(
              (assignedSupplier) => assignedSupplier.id === supplier.id
            );

            return {
              id: supplier.id,
              selectedFactories: supplier.selectedFactories?.filter(
                (selectedFactory) =>
                  supplierData?.factories?.find(
                    (factory) => factory.id === selectedFactory
                  )
              ),
            };
          }),
        ];
      });

      this.costRecords.fabricCost = costData.fabric.map((record) => {
        return [
          record.item,
          record.type,
          record.unit,
          this.roundOf(record.consumption),
          this.roundOf(record.unit_price),
          this.roundOf(record.wastage),
          this.roundOf(record.insurance),
          this.roundOf(record.finance),
          record.amount,
        ];
      });

      this.costRecords.sewingTrims = costData.trims
        .filter((record) => record.is_sewing)
        .map((record) => {
          return [
            record.item,
            JSON.parse(record.supplier)?.map((supplier) => {
              const supplierData = this.model.assignedSuppliers.find(
                (assignedSupplier) => assignedSupplier.id === supplier.id
              );

              return {
                id: supplier.id,
                selectedFactories: supplier.selectedFactories?.filter(
                  (selectedFactory) =>
                    supplierData?.factories?.find(
                      (factory) => factory.id === selectedFactory
                    )
                ),
              };
            }),
            record.unit,
            this.roundOf(record.consumption),
            this.roundOf(record.unit_price),
            this.roundOf(record.wastage),
            this.roundOf(record.insurance),
            this.roundOf(record.finance),
            record.amount,
          ];
        });

      this.costRecords.packingTrims = costData.trims
        .filter((record) => !record.is_sewing)
        .map((record) => {
          return [
            record.item,
            JSON.parse(record.supplier)?.map((supplier) => {
              const supplierData = this.model.assignedSuppliers.find(
                (assignedSupplier) => assignedSupplier.id === supplier.id
              );

              return {
                id: supplier.id,
                selectedFactories: supplier.selectedFactories?.filter(
                  (selectedFactory) =>
                    supplierData?.factories?.find(
                      (factory) => factory.id === selectedFactory
                    )
                ),
              };
            }),
            record.unit,
            this.roundOf(record.consumption),
            this.roundOf(record.unit_price),
            this.roundOf(record.wastage),
            this.roundOf(record.insurance),
            this.roundOf(record.finance),
            record.amount,
          ];
        });

      this.costRecords.embellishmentCharges = costData.embellishment_cost.map(
        (record) => {
          return [
            record.item,
            this.roundOf(record.unit_price),
            this.roundOf(record.wastage),
            this.roundOf(record.finance),
            record.amount,
          ];
        }
      );

      this.costRecords.cmFOBCharges = costData.cm_fob.map((record) => {
        return [record.item, record.amount];
      });

      this.costRecords.otherCost = costData.other_costs.map((record) => {
        return [
          record.item,
          record.cost,
          record.number_of_pieces,
          record.amount,
        ];
      });

      if (costData.per_piece_total_cost) {
        this.costRecords.fabricActualCost =
          costData.per_piece_total_cost.fabric_actual_cost !== null
            ? parseFloat(
                costData.per_piece_total_cost.fabric_actual_cost
              ).toFixed(
                costData.per_piece_total_cost.fabric_actual_cost % 1 !== 0
                  ? 2
                  : 0
              )
            : null;
        this.costRecords.trimActualCost =
          costData.per_piece_total_cost.trim_actual_cost !== null
            ? parseFloat(
                costData.per_piece_total_cost.trim_actual_cost
              ).toFixed(
                costData.per_piece_total_cost.trim_actual_cost % 1 !== 0 ? 2 : 0
              )
            : null;
        this.costRecords.embellishmentActualCost =
          costData.per_piece_total_cost.embellishment_actual_cost !== null
            ? parseFloat(
                costData.per_piece_total_cost.embellishment_actual_cost
              ).toFixed(
                costData.per_piece_total_cost.embellishment_actual_cost % 1 !==
                  0
                  ? 2
                  : 0
              )
            : null;
        this.costRecords.cmFobActualCost =
          costData.per_piece_total_cost.cm_fob_actual_cost !== null
            ? parseFloat(
                costData.per_piece_total_cost.cm_fob_actual_cost
              ).toFixed(
                costData.per_piece_total_cost.cm_fob_actual_cost % 1 !== 0
                  ? 2
                  : 0
              )
            : null;
        this.costRecords.otherActualCost =
          costData.per_piece_total_cost.other_actual_cost !== null
            ? parseFloat(
                costData.per_piece_total_cost.other_actual_cost
              ).toFixed(
                costData.per_piece_total_cost.other_actual_cost % 1 !== 0
                  ? 2
                  : 0
              )
            : null;
        this.costRecords.overHeadActualCost =
          costData.per_piece_total_cost.over_head_actual_cost !== null
            ? parseFloat(
                costData.per_piece_total_cost.over_head_actual_cost
              ).toFixed(
                costData.per_piece_total_cost.over_head_actual_cost % 1 !== 0
                  ? 2
                  : 0
              )
            : null;
        this.costRecords.overHeadValue =
          costData.per_piece_total_cost.over_head_value !== null
            ? parseFloat(costData.per_piece_total_cost.over_head_value).toFixed(
                costData.per_piece_total_cost.over_head_value % 1 !== 0 ? 2 : 0
              )
            : null;
        this.costRecords.profitMarginPercentage =
          costData.per_piece_total_cost.profit_margin_percentage !== null
            ? parseFloat(
                costData.per_piece_total_cost.profit_margin_percentage
              ).toFixed(
                costData.per_piece_total_cost.profit_margin_percentage % 1 !== 0
                  ? 2
                  : 0
              )
            : null;
        this.costRecords.conversionRateTodate =
          costData.per_piece_total_cost.google_conversion_rate_todate !== null
            ? parseFloat(
                costData.per_piece_total_cost.google_conversion_rate_todate
              ).toFixed(
                costData.per_piece_total_cost.google_conversion_rate_todate %
                  1 !==
                  0
                  ? 2
                  : 0
              )
            : null;
      }

      this.secondaryCostLoading = false;
    },

    setSecondaryPayload(keys, records) {
      const payload = {};
      for (let index = 0; index < keys.length; index++) {
        payload[keys[index]] =
          records[index] instanceof Array
            ? JSON.stringify(records[index])
            : records[index];
      }

      return payload;
    },

    saveSecondaryComment() {
      if (this.secondaryCostRecorsExist) {
        if (this.secondaryCommentWindow) {
          this.$refs.secondaryCostComment.validate();
          if (!this.secondaryCostComment) {
            return;
          }
          this.displayingSecondoryComment = this.secondaryCostComment;
          this.secondaryCommentClose();
        } else {
          this.secondaryCommentWindow = true;
          return;
        }
      }
      this.secondaryCostRecorsExist = true;
      this.saveSecondaryCost();
    },

    saveSecondaryCost() {
      const payload = {
        yarn: this.costRecords.fabricYarn.map((record) =>
          this.setSecondaryPayload(this.costKeys.fabricYarn, record)
        ),
        fabric: this.costRecords.fabricCost.map((record) =>
          this.setSecondaryPayload(this.costKeys.fabricCost, record)
        ),
        trims: [
          ...this.costRecords.sewingTrims.map((record) => ({
            ...this.setSecondaryPayload(this.costKeys.trimCost, record),
            is_sewing: true,
          })),
          ...this.costRecords.packingTrims.map((record) => ({
            ...this.setSecondaryPayload(this.costKeys.trimCost, record),
            is_sewing: false,
          })),
        ],
        embellishment_cost: this.costRecords.embellishmentCharges.map(
          (record) =>
            this.setSecondaryPayload(this.costKeys.embellishmentCharges, record)
        ),
        cm_fob: this.costRecords.cmFOBCharges.map((record) =>
          this.setSecondaryPayload(this.costKeys.cmFOBCharges, record)
        ),
        other_costs: this.costRecords.otherCost.map((record) =>
          this.setSecondaryPayload(this.costKeys.otherCost, record)
        ),
        per_piece_total_cost: {
          fabric_actual_cost:
            this.costRecords.fabricActualCost !== null &&
            this.costRecords.fabricActualCost !== ""
              ? this.costRecords.fabricActualCost
              : null,
          trim_actual_cost:
            this.costRecords.trimActualCost !== null &&
            this.costRecords.trimActualCost !== ""
              ? this.costRecords.trimActualCost
              : null,
          embellishment_actual_cost:
            this.costRecords.embellishmentActualCost !== null &&
            this.costRecords.embellishmentActualCost !== ""
              ? this.costRecords.embellishmentActualCost
              : null,
          cm_fob_actual_cost:
            this.costRecords.cmFobActualCost !== null &&
            this.costRecords.cmFobActualCost !== ""
              ? this.costRecords.cmFobActualCost
              : null,
          other_actual_cost:
            this.costRecords.otherActualCost !== null &&
            this.costRecords.otherActualCost !== ""
              ? this.costRecords.otherActualCost
              : null,
          over_head_actual_cost:
            this.costRecords.overHeadActualCost !== null &&
            this.costRecords.overHeadActualCost !== ""
              ? this.costRecords.overHeadActualCost
              : null,
          over_head_value:
            this.costRecords.overHeadValue !== null &&
            this.costRecords.overHeadValue !== ""
              ? this.costRecords.overHeadValue
              : null,
          profit_margin_percentage:
            this.costRecords.profitMarginPercentage !== null &&
            this.costRecords.profitMarginPercentage !== ""
              ? this.costRecords.profitMarginPercentage
              : null,
          google_conversion_rate_todate:
            this.costRecords.conversionRateTodate !== null &&
            this.costRecords.conversionRateTodate !== ""
              ? this.costRecords.conversionRateTodate
              : null,
        },
        comment: this.displayingSecondoryComment,
      };

      restAdapter
        .post(`/api/cost/${this.inquiryId}/secondary_cost`, payload)
        .then(() => {
          this.getSecondaryVersionsList();
          notification.success("Cost details saved successfully");
        })
        .catch((error) => {
          if (error.response.status == 400) {
            notification.errors(error.response.data.error);
          } else {
            notification.error("Something went wrong");
          }
        });
    },

    getSecondaryVersionsList() {
      restAdapter
        .get(`/api/secondary_cost_available_versions/${this.inquiryId}`)
        .then((resp) => {
          this.secondaryCostVersions = [];
          if (resp.data.secondary_cost_available_versions) {
            const max = resp.data.secondary_cost_available_versions + 1;
            for (let index = 0; index < max; index++) {
              this.secondaryCostVersions.push(index + 1);
            }
          }
          this.secondaryCostVersions.reverse();
          if (this.secondaryCostVersions.length > 1) {
            this.selectedSecondaryVersion = this.secondaryCostVersions[0];
            this.getSecondaryCommentData(this.selectedSecondaryVersion);
          }
        });
    },

    getSecondaryCommentData(version) {
      restAdapter
        .get(`/api/secondary_cost_version_comment/${this.inquiryId}/${version}`)
        .then((response) => {
          this.displayingSecondoryComment = response.data.comment;
        });
    },

    getSecondaryVersionsData() {
      this.secondaryCostLoading = true;
      if (
        !this.selectedSecondaryVersion ||
        this.selectedSecondaryVersion === this.secondaryCostVersions[0]
      ) {
        this.getSecondaryCost(this.inquiryId).then((response) => {
          this.setSecondaryCost(response.data);
        });
      } else {
        restAdapter
          .get(
            `/api/secondary_cost_version/${this.inquiryId}/${this.selectedSecondaryVersion}`
          )
          .then((response) => {
            this.setSecondaryCost(response.data);
          });
      }
      this.getSecondaryCommentData(this.selectedSecondaryVersion);
    },

    inputNumbersOnly(evt) {
      /*eslint no-var: "error"*/
      /* eslint no-var: off */
      /* eslint-disable */
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        (charCode != 46 && charCode != 45 && charCode < 48) ||
        charCode > 57 ||
        (charCode == 46 && evt.target.value.includes(".")) ||
        (charCode == 45 && evt.target.value.includes("-"))
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    setSuppliers(response) {
      this.model.assignedSuppliers = response.data.inquiry_assigned_suppliers;
      this.model.approvedSuppliers =
        response.data.approved_suppliers_but_not_assigned;
      if (this.model.assignedSuppliers.length) {
        this.model.allSuppliers = [
          ...this.model.assignedSuppliers.map((supplier) => ({
            ...supplier,
            id: supplier.id,
            selectedFactories: supplier.selectedFactories || [],
          })),
        ];
      } else {
        this.model.allSuppliers = [];
      }
    },

    async saveSuppliers(value) {
      this.supplierData[value] = { ...this.supplierData[value], loaded: false };
      this.supplierData = this.supplierData.map((data) => data);
      const suppliers = this.supplierData[value].suppliers.map(
        (selectedSupplier) => {
          return {
            supplier_id: parseInt(selectedSupplier.id),
            supplier_inquiry_assigned_status: this.model.assignedSuppliers.some(
              (supplier) => {
                return supplier.supplier_id == selectedSupplier.id;
              }
            ),
          };
        }
      );

      const costServiceId = this.costServiceData[value].cost_service_id;
      const inquiryCostServiceId = this.inquiryCostServiceId.filter((id) => {
        return id.cost_service_id == costServiceId;
      })[0].id;

      const payLoad = {
        cost_service_id: costServiceId,
        inquiry_cost_service_id: inquiryCostServiceId,
        suppliers: suppliers.length ? suppliers : [],
      };
      restAdapter
        .post(`/api/cost/${this.inquiryId}/assign_supplier_list`, payLoad)
        .then((response) => {
          this.supplierData[value] = {
            ...this.supplierData[value],
            loaded: true,
          };
          this.supplierData = this.supplierData.map((data) => data);
          this.checkDataIsUpdated();
        })
        .catch((error) => {
          this.supplierLoading = false;
        });
    },

    saveFactories(value, supplierId) {
      const factories = this.supplierData[value].suppliers.find(
        (supplier) => supplier.id == supplierId
      ).selectedFactories;

      const costServiceId = this.costServiceData[value].cost_service_id;
      const inquiryCostServiceId = this.inquiryCostServiceId.filter((id) => {
        return id.cost_service_id == costServiceId;
      })[0].id;

      const payLoad = {
        cost_service_id: costServiceId,
        inquiry_cost_service_id: inquiryCostServiceId,
        supplier_id: supplierId,
        factories: factories.length ? factories : [],
      };
      restAdapter
        .post(`/api/cost/${this.inquiryId}/add_supplier_factory_list`, payLoad)
        .then(() => this.checkDataIsUpdated());
    },

    getInquiry(id) {
      return restAdapter.get("/api/inquiries/" + id);
    },

    getServiceCost(id) {
      return restAdapter.get("/api/cost/" + id);
    },

    getServices() {
      return restAdapter.get("/api/costing_services");
    },

    getSupplierDetails() {
      return restAdapter.get(
        `/api/cost/${this.inquiryId}/supplier_list_to_assign`
      );
    },

    getCurrencies() {
      return restAdapter.get("/api/inquire_currencies");
    },

    checkServiceCostAvailability() {
      return restAdapter.get(`/api/inquiry/cost_status/${this.inquiryId}`);
    },

    // Not used, sometimes it will be used in future.
    // getCurrencyName(code) {
    //   const currency = this.currencies.find(
    //     (currency) => currency.currency_code == code
    //   );
    //   return currency && currency.display_name;
    // },

    getCostSheetAvailableVersions() {
      restAdapter
        .get(`/api/cost_available_versions/${this.inquiryId}`)
        .then((response) => {
          this.versions = [];
          if (this.costServiceData.length) {
            this.versions.push("Current Version");
          }
          this.versions = this.versions.concat(
            response.data.available_versions
          );
          this.currentVersion = response.data.current_version;
        });
    },

    getComments() {
      restAdapter
        .get(
          `/api/cost_version_comment/${this.inquiryId}/${this.selectedVersion}`
        )
        .then((response) => {
          this.model.comment = response.data.comment;
        })
        .catch((error) => {
          notification.error(error.response.data.error);
        });
    },

    async getCostSheetVersionData() {
      this.loading = true;
      this.initialDisable = true;
      this.model.comment = "";
      if (this.selectedVersion === "Current Version") {
        const response = await this.getInquiry(this.inquiryId);
        this.getComments();
        this.getServiceCost(this.inquiryId).then((response) => {
          this.costServiceData = this.costServiceData.map((item) => item);
          this.getCostSheetAvailableVersions();
          this.getComments();
          this.model.profitLost = response.data.final_profit_or_loss;
          this.model.brandCode = response.data.brand_code;
          this.model.totalProfit = response.data.total_profit;
          this.model.profitLossStatus = response.data.profit_or_loss_status;
          this.inquiryCostServiceId = response.data.cost_services_data;
          this.model.styleNumber = response.data.style_data.style_number;
          this.model.brandName = response.data.style_data.brand_name;
          this.model.styleName = response.data.style_data.style_name;
          this.supplierData = [];
          if (
            response.data.cost_services_data &&
            response.data.cost_services_data.length
          ) {
            this.model.currency = response.data.cost_services_data[0].currency;
            this.updatedCurrency = this.model.currency;
            this.costServiceData = response.data.cost_services_data.map(
              (data, index) => {
                this.supplierData.push({
                  cost_service_id: data.cost_service_id,
                  suppliers:
                    data.suppliers && data.suppliers.length
                      ? data.suppliers.map((selectedSupplier) => {
                          const supplierData = this.model.allSuppliers.find(
                            (supplier) => supplier.id == selectedSupplier.id
                          );

                          return {
                            ...supplierData,
                            selectedFactories:
                              selectedSupplier.factories?.filter((factoryId) =>
                                supplierData.factories?.find(
                                  (factory) => factoryId === factory.id
                                )
                              ) || [],
                          };
                        })
                      : [],
                  loaded: true,
                });
                return {
                  ...data,
                  ...this.costServiceData[index],
                  income:
                    data.income && !isNaN(parseFloat(data.income))
                      ? parseFloat(data.income).toFixed(2)
                      : null,
                  payment:
                    data.payment && !isNaN(parseFloat(data.payment))
                      ? parseFloat(data.payment).toFixed(2)
                      : null,
                };
              }
            );
            this.supplierData = this.supplierData.map((supplier) => supplier);
          }
          this.initialDisable = false;
          this.saving = false;
          this.currencyDisable = true;
        });
      } else {
        restAdapter
          .get(`/api/cost_version/${this.inquiryId}/${this.selectedVersion}`)
          .then((response) => {
            this.model.currency =
              response.data.available_version_data[0].currency;
            this.updatedCurrency = this.model.currency;
            this.getComments();
            this.supplierData = [];
            if (
              response.data.available_version_data &&
              response.data.available_version_data.length
            ) {
              this.costServiceData = response.data.available_version_data.map(
                (data, index) => {
                  this.supplierData.push({
                    cost_service_id: data.cost_service_id,
                    suppliers:
                      data.suppliers && data.suppliers.length
                        ? data.suppliers.map((selectedSupplier) => {
                            const supplierData = this.model.allSuppliers.find(
                              (supplier) => supplier.id == selectedSupplier.id
                            );

                            return {
                              ...supplierData,
                              selectedFactories:
                                selectedSupplier.factories?.filter(
                                  (factoryId) =>
                                    supplierData.factories?.find(
                                      (factory) => factoryId === factory.id
                                    )
                                ) || [],
                            };
                          })
                        : [],
                    loaded: true,
                  });
                  return {
                    ...data,
                    ...this.costServiceData[index],
                    income:
                      data.income && !isNaN(parseFloat(data.income))
                        ? parseFloat(data.income).toFixed(2)
                        : null,
                    payment:
                      data.payment && !isNaN(parseFloat(data.payment))
                        ? parseFloat(data.payment).toFixed(2)
                        : null,
                  };
                }
              );
              this.supplierData = this.supplierData.map((supplier) => supplier);
            }
            this.initialDisable = false;
            this.saving = false;
            this.currencyDisable = true;
          });
      }
      this.loading = false;
    },
    btnDialogOk() {
      this.updatedCurrency = this.model.currency;
      this.saving = true;
      this.dialog = false;
    },
    btnDialogCancel() {
      this.model.currency = this.updatedCurrency;
      this.currencyDisable = true;
      this.saving = false;
      this.dialog = false;
    },

    checkCurrencyIsUpdated() {
      if (!this.currencyDisable) {
        this.dialog = true;
        this.model.newComment = null;
        this.$refs.addComment?.resetValidation();
      } else {
        this.saving = false;
        this.dialog = false;
      }
    },

    checkDataIsUpdated() {
      this.saving = true;
      this.model.newComment = null;
      this.$refs.addComment?.resetValidation();
    },

    userCommentClose() {
      this.userComment = false;
      this.$refs.addComment?.resetValidation();
      this.model.newComment = null;
    },

    secondaryCommentClose() {
      this.secondaryCommentWindow = false;
      this.$refs.secondaryCostComment?.resetValidation();
      this.secondaryCostComment = null;
    },
    makeCurrentVersion() {
      this.userComment = true;
    },
    makeSecondaryCurrentVersion() {
      this.secondaryCommentWindow = true;
    },
    saveServiceData() {
      if (this.userComment) {
        this.$refs.addComment.validate();
        if (!this.model.newComment) {
          return;
        }
      }
      const costServices = this.costServiceData.map((data) => {
        const supplierDetails = this.supplierData.find(
          (service) => service.cost_service_id === data.id
        ).suppliers;
        return {
          ...data,
          income:
            data.income && !isNaN(parseFloat(data.income))
              ? parseFloat(data.income).toFixed(2)
              : 0,
          payment:
            data.payment && !isNaN(parseFloat(data.payment))
              ? parseFloat(data.payment).toFixed(2)
              : 0,
          suppliers: supplierDetails
            ? supplierDetails.map((item) => ({
                id: item.id,
                factories: item.selectedFactories,
              }))
            : [],
        };
      });
      const payLoad = {
        cost_services: costServices,
        style_name: this.model.styleName,
        currency: this.model.currency,
        comment: this.model.newComment,
        new_cost_sheet: this.newCostSheet,
      };

      restAdapter
        .post(`/api/cost/${this.inquiryId}`, payLoad)
        .then((response) => {
          notification.success("Costing details saved successfully");
          this.getCostSheetAvailableVersions();
          this.selectedVersion = "Current Version";
          this.getCostSheetVersionData();
        })
        .catch((error) => {
          this.loading = false;
          if (
            error.response &&
            error.response.status == 400 &&
            error.response.data &&
            error.response.data.error
          ) {
            notification.errors(error.response.data.error);
          } else {
            notification.error("Something went wrong");
          }
        });

      this.newCostSheet = false;
      this.userComment = false;
      this.model.newComment = null;
      this.$refs.addComment?.resetValidation();
      this.saving = false;
    },
  },

  async mounted() {
    this.inquiryId = this.$route.params.id;
    await this.getServices(this.inquiryId)
      .then((res) => {
        this.costServiceData = res.data.services.map((item) => {
          return {
            cost_service_id: item.id,
            display_name: item.display_name,
            id: item.id,
            income: null,
            inquiry_id: null,
            name: item.name,
            payment: null,
          };
        });
      })
      .catch((error) => {
        if (error.response.status === 401) {
          notification.error("You don't have Access");
          this.$router.push({ name: routes.COLLECTION_LIST });
        }
      });

    await this.getSupplierDetails(this.inquiryId).then((response) => {
      this.setSuppliers(response);
    });

    await this.getCostSheetVersionData();

    await this.checkServiceCostAvailability().then((response) => {
      if (response.data.new_cost_sheet) {
        this.newCostSheet = true;
      } else {
        this.newCostSheet = false;
      }
    });

    this.saving = false;
    this.initialDisable = false;

    this.getSecondaryVersionsList();
    this.getSecondaryCost(this.inquiryId).then((response) => {
      this.unitOfMeasurements = response.data.measurements.map(
        (measurement) => ({
          id: measurement.id,
          name: measurement.measurement_name,
        })
      );

      this.secondaryCostRecorsExist =
        response.data.yarn.length ||
        response.data.fabric.length ||
        response.data.trims.length ||
        response.data.cm_fob.length ||
        response.data.other_costs.length ||
        response.data.embellishment_cost.length ||
        response.data.per_piece_total_cost;

      this.bulkTotal = response.data.inquiry_bulk_order_qty;
      this.setSecondaryCost(response.data);
    });
  },

  beforeMount() {
    this.getCurrencies().then((response) => {
      this.currencies = response.data.currencies;
    });
  },

  computed: {
    isAccountVerified() {
      return auth.user.account?.verified;
    },
    totalFabricCost() {
      let total = 0;
      for (let index = 0; index < this.costRecords.fabricCost.length; index++) {
        const lastElementVal = parseFloat(
          this.costRecords.fabricCost[index][
            this.costHeaders.fabricCost.length - 1
          ]
        );
        if (lastElementVal) {
          total += lastElementVal;
        }
      }
      return total ? parseFloat(total).toFixed(2) : null;
    },
    totalSewingTrimCost() {
      let total = 0;
      for (
        let index = 0;
        index < this.costRecords.sewingTrims.length;
        index++
      ) {
        const lastElementVal = parseFloat(
          this.costRecords.sewingTrims[index][
            this.costHeaders.trimCost.length - 1
          ]
        );
        if (lastElementVal) {
          total += lastElementVal;
        }
      }
      return total ? parseFloat(total).toFixed(2) : null;
    },
    totalPackingTrimCost() {
      let total = 0;
      for (
        let index = 0;
        index < this.costRecords.packingTrims.length;
        index++
      ) {
        const lastElementVal = parseFloat(
          this.costRecords.packingTrims[index][
            this.costHeaders.trimCost.length - 1
          ]
        );
        if (lastElementVal) {
          total += lastElementVal;
        }
      }
      return total ? parseFloat(total).toFixed(2) : null;
    },
    totalTrimCost() {
      const total =
        parseFloat(this.totalSewingTrimCost ? this.totalSewingTrimCost : 0) +
        parseFloat(this.totalPackingTrimCost ? this.totalPackingTrimCost : 0);
      return total ? parseFloat(total).toFixed(2) : null;
    },
    totalEmbellishmentCost() {
      let total = 0;
      for (
        let index = 0;
        index < this.costRecords.embellishmentCharges.length;
        index++
      ) {
        const lastElementVal = parseFloat(
          this.costRecords.embellishmentCharges[index][
            this.costHeaders.embellishmentCharges.length - 1
          ]
        );
        if (lastElementVal) {
          total += lastElementVal;
        }
      }
      return total ? parseFloat(total).toFixed(2) : null;
    },
    totalcmFOBCharges() {
      let total = 0;
      for (
        let index = 0;
        index < this.costRecords.cmFOBCharges.length;
        index++
      ) {
        const lastElementVal = parseFloat(
          this.costRecords.cmFOBCharges[index][
            this.costHeaders.cmFOBCharges.length - 1
          ]
        );
        if (lastElementVal) {
          total += lastElementVal;
        }
      }
      return total ? parseFloat(total).toFixed(2) : null;
    },
    totalOtherCost() {
      let total = 0;
      for (let index = 0; index < this.costRecords.otherCost.length; index++) {
        const lastElementVal = parseFloat(
          this.costRecords.otherCost[index][
            this.costHeaders.otherCost.length - 1
          ]
        );
        if (lastElementVal) {
          total += lastElementVal;
        }
      }
      return total ? parseFloat(total).toFixed(2) : null;
    },
    pieceNetFOB() {
      const total =
        (this.totalFabricCost ? parseFloat(this.totalFabricCost) : 0) +
        (this.totalTrimCost ? parseFloat(this.totalTrimCost) : 0) +
        (this.totalEmbellishmentCost
          ? parseFloat(this.totalEmbellishmentCost)
          : 0) +
        (this.totalcmFOBCharges ? parseFloat(this.totalcmFOBCharges) : 0) +
        (this.totalOtherCost ? parseFloat(this.totalOtherCost) : 0) +
        (this.costRecords.overHeadValue
          ? parseFloat(this.costRecords.overHeadValue)
          : 0);

      return total ? parseFloat(total).toFixed(2) : null;
    },
    pieceNetFOBActualCost() {
      const total =
        (this.costRecords.overHeadValue
          ? parseFloat(this.costRecords.overHeadValue)
          : 0) +
        (this.costRecords.fabricActualCost
          ? parseFloat(this.costRecords.fabricActualCost)
          : 0) +
        (this.costRecords.trimActualCost
          ? parseFloat(this.costRecords.trimActualCost)
          : 0) +
        (this.costRecords.embellishmentActualCost
          ? parseFloat(this.costRecords.embellishmentActualCost)
          : 0) +
        (this.costRecords.cmFobActualCost
          ? parseFloat(this.costRecords.cmFobActualCost)
          : 0) +
        (this.costRecords.otherActualCost
          ? parseFloat(this.costRecords.otherActualCost)
          : 0) +
        (this.costRecords.overHeadActualCost
          ? parseFloat(this.costRecords.overHeadActualCost)
          : 0);
      return total ? parseFloat(total).toFixed(2) : null;
    },
    pieceProfitMargin() {
      const total =
        this.pieceNetFOB && this.costRecords.profitMarginPercentage
          ? (parseFloat(this.pieceNetFOB) *
              parseFloat(this.costRecords.profitMarginPercentage)) /
            100
          : null;
      return total ? parseFloat(total).toFixed(2) : null;
    },
    pieceFinalFOBUS() {
      const total =
        parseFloat(this.pieceNetFOB) +
        parseFloat(this.pieceProfitMargin ? this.pieceProfitMargin : 0);
      return total ? parseFloat(total).toFixed(2) : null;
    },
    pieceFinalFOBGBP() {
      const total = this.pieceFinalFOBUS
        ? parseFloat(this.pieceFinalFOBUS) *
          parseFloat(this.costRecords.conversionRateTodate || 0) *
          1.06
        : null;
      return total !== null ? parseFloat(total).toFixed(2) : null;
    },
    totalPaymentValue() {
      let profits = 0;
      for (let i = 0; i < this.costServiceData.length; i++) {
        const value = this.costServiceData[i].payment;
        if (value) {
          profits += parseFloat(value);
        }
      }
      return parseFloat(profits).toFixed(2);
    },
    // Not used, sometimes it will be used in future.
    // userCheck() {
    //   if (auth.user.id === this.accountManagerId) {
    //     return true;
    //   }
    //   return false;
    // },
    getCurrencySymbol() {
      switch (this.updatedCurrency) {
        case "USD":
          return "$";
        case "GBP":
          return "£";
        case "EUR":
          return "€";
        default:
          return "";
      }
    },

    totalProfitValue() {
      let profits = 0;
      for (let i = 0; i < this.costServiceData.length; i++) {
        const payment = this.costServiceData[i].payment;
        const income = this.costServiceData[i].income;
        if (payment && income) {
          profits += parseFloat(income) - parseFloat(payment);
        } else if (payment) {
          profits += -parseFloat(payment);
        } else if (income) {
          profits += parseFloat(income);
        }
      }
      return parseFloat(profits).toFixed(2);
    },
  },
};
</script>

<style lang="scss" scoped>
.product-development::v-deep .v-select__selection--comma {
  display: none;
}

.product-development::v-deep table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.product-development::v-deep td {
  border: 1px solid #dddddd;
  border-color: #778899;
  text-align: left;
  padding: 8px;
}

.product-development::v-deep th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.costing-table-container {
  margin-top: 15px;
  background-color: white;
  padding: 10px;
  justify-content: center;
}

// .costing-table-container::v-deep .v-select__selection--comma {
//   display: none;
// }

.costing-table-container::v-deep table {
  margin: 15px 0px;
  font-family: arial, sans-serif;
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
}

.costing-table-container::v-deep td {
  border: 1px solid #dddddd;
  border-color: #778899;
  text-align: left;
  padding: 0px 4px;
}

.costing-table-container::v-deep th {
  border: 1px solid #dddddd;
  text-align: left;
}

.save_button_color {
  background-color: #492a67 !important;
  border: #492a67 !important;
  display: flex;
}
.container {
  gap: 10px;
  background-color: #eef1fa;
  padding: 10px;
}

#th1 {
  background-color: #f0ffff;
  text-align: center;
  border-right-color: #f0ffff;
}

#th1-dropdown {
  background-color: #f0ffff;
  border-left-color: #f0ffff;
}

#th1-cus {
  background-color: #ffffe0;
  width: 10%;
}

#th1-cus-dropdown {
  background-color: whitesmoke;
  width: 14%;
}

#profit-payment {
  background-color: #9cc2e5;
  width: 10%;
}

#th1-cus-head {
  background-color: #f0ffff;
  width: 10%;
  text-align: center;
}

#th1-cus-head-2 {
  background-color: #f0ffff;
  width: 10%;
  border-right-color: #f0ffff;
}

#th1-par {
  background-color: #f0ffff;
  width: 20%;
}

#th1-total {
  background-color: #f0ffff;
  border-right-color: #f0ffff;
}

#td1 {
  background-color: #92d050;
}

#profit {
  background-color: #9cc2e5;
}

#main-table-head {
  background-color: #f0f8ff;
}

.btn-align {
  display: flex;
  justify-content: flex-end;
  -webkit-justify-content: flex-end;
}

.no-suppliers-text {
  font-size: 14px;
  cursor: pointer;
  color: #442d65;
}

.no-suppliers-text:hover {
  text-decoration: underline;
  color: #c6a8ec;
}

input[type="text"],
select {
  width: 100%;
  border: none;
}

input:focus {
  outline: none;
}

.select-currency {
  border: none;
  outline: none;
}

.list_color.md-selected::v-deep .md-list-item-content {
  color: #fa735f !important;
}

.list_color.md-selected::v-deep .md-list-item-text {
  color: #fa735f !important;
}

.md-field.md-theme-default.md-focused label {
  color: #582963 !important;
}

.md-field.md-theme-default:before {
  background-color: #c6a8ec !important;
}

.md-field.md-theme-default.md-has-textarea:before {
  border-color: #c6a8ec !important;
  background-color: transparent !important;
}

.version-container {
  display: flex;
  justify-content: right;
  -webkit-justify-content: right;
}

.field_size {
  width: 210px;
  margin: 0;
}

.required-field {
  color: red;
  font-size: 20px;
}

.comment-container {
  margin: 8px 0px 0px 1px;
  width: 100%;
  min-height: 150px;
  text-align: left;
}

.total-td {
  margin: 5px 0px;
}

.subtitle {
  background-color: $assignButton;
}

.filter-container {
  display: flex;
  justify-content: flex-end;
  margin-right: 40px;
}

.filter-item {
  margin-left: 15px;
}

.filter-label {
  margin-left: 8px;
}

.disabled-td {
  background-color: $light;
}

.packing-trims::before {
  height: 15px;
  display: table-row;
  content: "";
}

.make-version {
  background-color: #492a67 !important;
  border: #492a67 !important;
  display: flex;
  margin-right: 0px !important;
}

.not-verified-text {
  color: #ff0000;
  text-align: left;
  margin-bottom: 10px;
}
</style>
