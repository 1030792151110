<template>
  <div class="container">
    <div v-if="styleListLoading" class="text-center loading-container mt-5">
      <md-progress-spinner
        class="color_stroke"
        md-mode="indeterminate"
      ></md-progress-spinner>
    </div>
    <div v-else class="list-container">
      <v-row class="list-row">
        <v-col cols="5">
          <div class="edit-container">
            <div class="switch-container">
              <v-switch
                v-model="isEditable"
                color="#473068"
                :disabled="warnMessage"
                class="edit-switch"
              ></v-switch>
              <div class="edit-container">
                <span class="switch-label">Edit</span>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      small
                      class="more-vert-icon edit-icon"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-alert-circle-outline
                    </v-icon>
                  </template>
                  <span>Switch to edit sub tasks</span>
                </v-tooltip>
              </div>
            </div>
          </div>
          <ul class="list-data">
            <li
              v-for="mainTask in styleTaskList"
              :key="mainTask.id"
              class="main-task-item"
            >
              <div
                class="main-task"
                @click="toggleMainTask(mainTask.id)"
                :class="{ expanded: insideMainTask === mainTask.id }"
              >
                <span class="main-task-name">{{ mainTask.name }}</span>
                <v-icon class="toggle-icon">
                  {{
                    insideMainTask === mainTask.id ? "mdi-minus" : "mdi-plus"
                  }}
                </v-icon>
              </div>
              <Container
                v-if="insideMainTask === mainTask.id"
                :lockAxis="'y'"
                @drop="onDrop(mainTask.id,$event)"
                class="sub-task-list"
              >
                <template v-if="isEditable">
                  <Draggable
                    v-for="subTask in mainTask.sub_tasks.filter(task => mainTask.id === task.style_file_task_id)"
                    :key="subTask.id"
                    :handle="'draggable-handle'"
                    class="sub-task-item"
                    :ghost-class="null"
                    @end="onDrop(mainTask.id, $event)"
                  >
                    <div
                      class="sub-task"
                      :class="{
                        'selected-sub-task': subtaskClick === subTask.id,
                      }"
                      @click="subtaskClicked(subTask.style_file_task_id, subTask)"
                    >
                      <v-icon
                        class="icon draggable-handle"
                        style="margin-left: 15px; cursor: move;"
                      >
                        mdi-menu
                      </v-icon>
                      <v-textarea
                        :class="['subtask-textarea', subTaskClass(subTask.name), {'scroll-enabled': subTask.id === subtaskClick }]"
                        auto-grow
                        row-height="1"
                        v-model="subTask.name"
                        placeholder="Sub task item"
                        v-on:input="debounceRename(subTask.id, subTask.name)"
                        :rules="rules.nameRule"
                        :ref="'subTaskDetails-' + subTask.id"
                        @focus="$event.target.scrollHeight > 50 && enableScroll($event, subTask.id)"
                        @blur="disableScroll($event, subTask.id)"
                      />
                      <div class="delete-icon">
                        <span class="file-count">{{ subTask.files.length }}</span>
                        <v-icon class="file-icon">mdi-file</v-icon>
                        <v-icon
                          class="bin-icon"
                          @click="removeRow(subTask.id)"
                        >mdi-trash-can-outline</v-icon>
                      </div>
                    </div>
                  </Draggable>
                </template>

                <template v-else>
                  <div
                    v-for="subTask in mainTask.sub_tasks.filter(task => mainTask.id === task.style_file_task_id)"
                    :key="subTask.id"
                    class="sub-task-item"
                  >
                    <div
                      class="sub-task"
                      :class="{
                        'selected-sub-task': subtaskClick === subTask.id,
                      }"
                      @click="subtaskClicked(subTask.style_file_task_id, subTask)"
                    >
                      <div style="width: 24px; margin-left: 15px;"></div>
                      <v-tooltip v-if="subTask.name.length > 40" bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on" class="single-line-truncate">
                            {{ truncateName(subTask.name) }}
                          </span>
                        </template>
                        <span>{{ subTask.name }}</span>
                      </v-tooltip>
                      <span v-else class="single-line-truncate">{{ subTask.name }}</span>
                      <div class="delete-icon">
                        <span class="file-count">{{ subTask.files.length }}</span>
                        <v-icon class="file-icon">mdi-file</v-icon>
                      </div>
                    </div>
                  </div>
                </template>

                <li class="add-subtask-item">
                  <v-icon
                    v-if="isEditable"
                    style="font-size: 30px"
                    @click="addRow(mainTask.id)"
                    :disabled="checkSubTaskNameIsEmpty(mainTask.id)"
                  >mdi-playlist-plus</v-icon>
                </li>
              </Container>
            </li>
          </ul>
        </v-col>
        <v-col class="second-col">
          <div class="file-upload-container" v-if="subtaskClick">
            <StyleFilesContainer
              :inquiryId="parseInt(inquiryId)"
              :subTaskId="subtaskClick"
              :subTaskData="getFiles"
              :companies="companies"
              :subTaskName="subtaskName"
              :subTaskApproveStatus="subTaskApproveStatus"
              :warnMessage="warnMessage"
              @subtask-approval="subtaskApproval"
              @update-file-visibility="updateFileVisibility"
              @reload-data="getStyleFile()"
              @refresh-activity-log="getActivityLog()"
            />
          </div>
        </v-col>
      </v-row>
    </div>
    <v-row
      v-if="!styleListLoading"
      class="mb-3 mt-5"
      style="padding: 1px 25px 0px 24px"
    >
      <v-col class="activity-log-title-container" cols="12">
        <span class="activity-log-title">Activity Log</span>
        <v-icon
          v-if="!showActivityLog"
          @click="showActivityLog = true"
          class="ml-5 activity-log-title icon-class"
          large
          >expand_more</v-icon
        >
        <v-icon
          v-else
          @click="showActivityLog = false"
          class="ml-5 activity-log-title icon-class"
          large
          >expand_less</v-icon
        >
      </v-col>
    </v-row>
    <md-card class="container" v-if="showActivityLog">
      <form>
        <md-card-content class="search-content">
          <v-row class="inquiry-search-bar">
            <v-col cols="6">
              <v-text-field
                label="Search"
                v-on:input="debounceSearch"
                name="search"
                id="search"
                v-model="query"
                clearable
              />
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                :items="styleTaskList"
                item-text="name"
                item-value="id"
                v-on:input="debounceSearch"
                data-cy="sub-task"
                name="sub-task"
                id="sub-task"
                v-model="sortMainTaskId"
                placeholder="Main Task"
                clearable
              >
                <template v-slot:item="{ item }">
                  {{ item.name }}
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
        </md-card-content>
      </form>
      <md-card class="topics-container">
        <md-card-content>
          <v-row align="center" style="margin: -17px">
            <v-col cols="5">
              <span class="activity-title"> Activity </span>
            </v-col>
            <v-col cols="2">
              <span class="activity-title"> Business name </span>
            </v-col>
            <v-col cols="2">
              <span class="activity-title"> Date </span>
            </v-col>
            <v-col cols="3">
              <span class="activity-title"> Main task </span>
            </v-col>
          </v-row>
        </md-card-content>
      </md-card>
      <div v-if="activityLogLoading" class="text-center loading-container">
        <md-progress-spinner
          class="color_stroke"
          md-mode="indeterminate"
        ></md-progress-spinner>
      </div>
      <div v-else>
        <div v-if="!activityLog.length" class="empty-container text-center">
          <div class="empty-data">No activities found</div>
        </div>
        <div v-else>
          <md-card
            v-for="notification in activityLog"
            v-bind:key="notification.id"
            class="notification-container"
          >
            <md-card-content>
              <v-row align="center" style="margin: -17px">
                <v-col cols="5">
                  <span class="notification-message">
                    {{ notification.message }}
                  </span>
                </v-col>
                <v-col cols="2">
                  <v-chip class="chip-style" color="#FFF2F0">
                    <span class="sender-name">
                      {{ notification.creator.organization_registry.name }}
                    </span>
                  </v-chip>
                </v-col>
                <v-col cols="2" class="md-caption">
                  <span class="date-style">
                    {{ new Date(notification.created_at).toLocaleString() }}
                  </span>
                </v-col>
                <v-col cols="3">
                  <v-chip class="chip-style" color="rgba(112, 208, 206, 0.18)">
                    <div class="description-child-container">
                      <div class="main-task-name">
                        {{ notification.style_file_task.name }}
                      </div>
                    </div>
                  </v-chip>
                </v-col>
              </v-row>
            </md-card-content>
          </md-card>
        </div>
        <Pagination
          style="margin: -9px"
          :current-page="currentPage"
          :total-count="totalCount"
          @load-page="loadPage"
          v-if="activityLog.length"
        />
      </div>
    </md-card>
  </div>
</template>

<script>

/* eslint-disable @typescript-eslint/camelcase */
import restAdapter from "../../restAdapter";
import debounce from "lodash/debounce";
import Pagination from "../../components/Pagination";
import notification from "../../notification";
import StyleFilesContainer from "../inquiry/StyleFilesContainer.vue";
import { inquiryDataBus } from "../../utils/inquiryDataBus";
import { Container, Draggable } from 'vue-smooth-dnd';

export default {
  name: "StyleFile",
  components: {
    Pagination,
    StyleFilesContainer,
    Container,
    Draggable,
  },
  props: {
    collectionBrand: {
      type: Object,
      default: null,
    },
    collectionCreatedBy: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    isEditable: false,
    inquiryId: null,
    subtaskClick: null,
    insideMainTask: 1,
    approve: [],
    query: "",
    sortMainTaskId: null,
    draggedSubTaskIndex: null,
    draggedMainTaskId: null,
    hoverItemId: null,
    styleTaskList: [],
    subTaskList: [],
    styleSubTaskList: [],
    activeTab: "styleList",
    activityLog: [],
    styleListLoading: false,
    activityLogLoading: false,
    suppliers: [],
    files: [{ id: 1 }],
    currentPage: 1,
    subtaskName: "",
    subTaskApproveStatus: false,
    totalCount: 0,
    showActivityLog: false,
    isAuthority: false,
    typing: null,
    rules: {
      nameRule: [
        (value) => !!value || "Name is required",
        (value) =>
          !value ||
          (value && value.length <= 255) ||
          "Must be no more than 255 characters",
      ],
    },
  }),

  filters: {
    truncate: function (text, stop, clamp) {
      return text.slice(0, stop) + (stop < text.length ? clamp || "..." : "");
    },
  },

  created() {
    inquiryDataBus.$on("reload-style-file", this.reloadStyleFileData);
  },

  computed: {
    getFiles() {
      if (!this.subtaskClick) {
        return [];
      }
      return this.styleSubTaskList.find(
        (subTask) => subTask.id == this.subtaskClick
      ).files;
    },
    subTaskClass() {
      return (subTaskName) => {
        return subTaskName.length <= 40 ? 'single-line-subtask' : '';
      };
    },

    warnMessage() {
      if (!this.isEditable) return false;
      const typed = this.typing;
      if (this.subtaskClick && (typed || !typed)) {
        const ref = this.$refs["subTaskDetails-" + this.subtaskClick];
        if (ref && ref[0] && !ref[0].validate()) {
          return true;
        }
      }
      return false;
    },
    companies() {
      const companies = this.suppliers;
      if (this.collectionBrand) {
        companies.push(this.collectionBrand);
      }
      if (
        this.collectionCreatedBy &&
        (!this.collectionBrand ||
          this.collectionCreatedBy.id !== this.collectionBrand.id)
      ) {
        companies.push(this.collectionCreatedBy);
      }
      return companies;
    },
  },

  mounted() {
    this.initialize();
    this.setupScrolling();
  },

  methods: {
    async initialize() {
      this.inquiryId = this.$route.params.id;
      this.getInquirySuppliers();
      this.getStyleFile(true);
      this.getActivityLog(true);
    },

    onDrop(mainTaskId, dropResult) {

      const { removedIndex, addedIndex } = dropResult;

      if (removedIndex !== null && addedIndex !== null) {
        const mainTask = this.styleTaskList.find(task => task.id === mainTaskId);
        if (mainTask) {
          const newSubTasks = [...mainTask.sub_tasks];
          const movedItem = newSubTasks.splice(removedIndex, 1)[0];
          newSubTasks.splice(addedIndex, 0, movedItem);

          this.$set(mainTask, 'sub_tasks', newSubTasks);

          this.updateSubTaskOrder(mainTaskId);
        }
      }
    },
    updateSubTaskOrder(mainTaskId) {
      const mainTask = this.styleTaskList.find(task => task.id === mainTaskId);
      const subTaskIdsInOrder = mainTask.sub_tasks.map(subTask => subTask.id);

      restAdapter.put(
        `/api/inquiries/${this.inquiryId}/style_files/${mainTaskId}/update_order`, 
        {
          sub_task_ids_in_order: subTaskIdsInOrder
        }
      )
      .then(() => {
        notification.success('Sub-task order updated successfully');
        this.getActivityLog();
      })
      .catch(error => {
        notification.error('Error updating sub-task order', error);
      });
    },
    

    toggleMainTask(id) {
      this.insideMainTask = this.insideMainTask === id ? null : id;
    },

    reloadStyleFileData() {
      this.getStyleFile();
      this.getActivityLog();
    },

    subtaskClicked(taskId, sub) {
      this.subtaskClick = sub?.id;
      this.insideMainTask = taskId;
      this.subTaskApproveStatus = sub?.approve_status;
    },

    debounceSearch: debounce(function () {
      this.currentPage = 1;
      this.getActivityLog();
    }, 1000),

    getActivityLog(loading = false) {
      this.activityLogLoading = loading;
      let url =
        `/api/inquiries/${this.inquiryId}/style_file_log?page=` +
        this.currentPage;
      url += "&query=" + (this.query == null ? "" : this.query);
      url +=
        "&main_task=" +
        (this.sortMainTaskId == null ? "" : this.sortMainTaskId);

      restAdapter.get(url).then((response) => {
        this.activityLogLoading = false;
        this.activityLog = response.data.data;
        this.totalCount = response.data.meta.total;
      });
    },

    truncateName(name) {
      return name.length > 40 ? name.substring(0, 40) + "..." : name;
    },

    loadPage(page) {
      this.currentPage = page;
      this.getActivityLog();
    },

    subtaskApproval(id) {
      this.subTaskApproveStatus = !this.subTaskApproveStatus;
      restAdapter
        .put(
          `/api/inquiries/${this.inquiryId}/style_files/${id}/approve_style_sub_task`
        )
        .then(() => {
          let subTaskName = "";
          this.styleTaskList = this.styleTaskList.map((task) => {
            if (task.id === this.insideMainTask) {
              // eslint-disable-next-line @typescript-eslint/camelcase
              task.sub_tasks = task.sub_tasks.map((subTask) => {
                if (subTask.id === id) {
                  // eslint-disable-next-line @typescript-eslint/camelcase
                  subTask.approve_status = !subTask.approve_status;
                  subTaskName = subTask.name;
                }
                return subTask;
              });
            }
            return task;
          });
          notification.success(
            subTaskName + " sub task status updated successfully"
          );
          this.getActivityLog();
        });
    },

    getInquirySuppliers() {
      restAdapter
        .get(`/api/inquiries/${this.inquiryId}/assigned_suppliers`)
        .then((response) => {
          this.suppliers = response.data.inquiry_assigned_suppliers;
        });
    },

    updateFileVisibility(data) {
      this.styleSubTaskList = this.styleSubTaskList.map((subTask) => {
        if (subTask.id === data.subTaskId) {
          subTask.files = subTask.files.map((file) => {
            if (file.upload_name === data.fileUniqueId) {
              // eslint-disable-next-line
              file.is_private_in_style_file = data.isPrivate;
            }
            return file;
          });
        }
        return subTask;
      });
    },

    getStyleFile(loading = false) {
      this.styleListLoading = loading;
      restAdapter
        .get(`/api/inquiries/${this.inquiryId}/style_files`)
        .then((response) => {
          const data = response.data.style_file_data;
          this.styleTaskList = data;
          this.styleSubTaskList = data.map((item) => item.sub_tasks).flat();
          this.isAuthority = response.data.is_authority;
          if (this.styleSubTaskList?.length && loading) {
            this.subtaskClicked(
              this.styleSubTaskList[0].style_file_task_id,
              this.styleSubTaskList[0]
            );
          } else if (loading) {
            this.subtaskClicked(null, null);
          }
          this.styleListLoading = false;
        });
    },

    enableScroll(event, subTaskId) {
      if (event.target.scrollHeight > 50) {
        this.subtaskClick = subTaskId;
      }
    },
    disableScroll(event, subTaskId) {
      this.subtaskClick = null;
    },

    debounceRename: debounce(function (subTaskId, subTaskName) {
      this.typing = `${subTaskName}-${subTaskId}`;
      this.editSubtask(subTaskId, subTaskName);
    }, 500),

    editSubtask(subTaskId, subTaskName) {
      const ref = this.$refs["subTaskDetails-" + subTaskId][0];
      if (!ref.validate()) {
        return;
      }
      restAdapter
        .put(
          `/api/inquiries/${this.inquiryId}/style_files/${subTaskId}/rename`,
          { name: subTaskName }
        )
        .then((response) => {
          this.getActivityLog();
        });
    },

    addRow(taskId) {
      restAdapter
        .post(
          `/api/inquiries/${this.inquiryId}/style_file_tasks/${taskId}/style_file_sub_task`
        )
        .then((response) => {
          this.reloadStyleFileData();
        });
    },

    removeRow(subTaskId) {
      confirm("Are you sure you want to delete this sub task?") &&
        restAdapter
          .delete(`/api/inquiries/${this.inquiryId}/style_files/${subTaskId}`)
          .then((response) => {
            const index = this.styleSubTaskList.findIndex(
              (item) => item.id === subTaskId
            );
            const mainTaskId = this.styleSubTaskList[index]?.style_file_task_id;

            this.styleSubTaskList = this.styleSubTaskList.filter(
              (item) => item.id != subTaskId
            );

            const currentMainTaskSubTasks = this.styleSubTaskList.filter(
              (subTask) => subTask.style_file_task_id === this.insideMainTask
            );

            if (!currentMainTaskSubTasks.length) {
              this.subtaskClick = null;
            } else {
              const previousIndex = Math.max(0, index - 1);
              const previousSubTask = this.styleSubTaskList[previousIndex];
              this.subtaskClicked(
                previousSubTask.style_file_task_id,
                previousSubTask
              );
            }

            this.reloadStyleFileData();
          })
          .catch((error) => {
            notification.error(
              "Cannot delete the sub-task as it includes files uploaded by a different company assigned to this style."
            );
          });
    },

    checkSubTaskNameIsEmpty(subTaskId) {
      return this.styleTaskList
        .filter((item) => item.id == subTaskId)
        .map((item) => item.sub_tasks)
        .flatMap((item) => item)
        .map((item) => !item.name)
        .includes(true);
    },
  },
};
</script>

<style lang="scss" scoped>
.loading-container {
  height: 500px;
}

.color_stroke::v-deep .md-progress-spinner-circle {
  stroke: #492a67 !important;
}

.container {
  padding: 10px;
  width: 100%;
  box-shadow: unset;
  background-color: white;
  border-radius: 8px;
}

.empty-container {
  min-height: 380px;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  padding-top: 100px;
}

.empty-data {
  font-weight: 600;
  font-size: 16px;
  color: #473068;
}

.subtitle {
  padding: 0px 15px;
}

.subtitle-files {
  background-color: #473068;
  color: #ffffff;
}

.file-upload-container {
  padding-top: 15px;
}

.switch-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: -20px;
  margin-right: 20px;
  margin-bottom: -10px;
}

.switch-label {
  margin-left: 5px;
  color: #473068;
}

.edit-icon {
  transition: transform 0.2s;
  margin-left: 5px;
}

.edit-icon:hover {
  transform: scale(1.2);
}

.status {
  justify-content: center;
  display: flex;
  border: 1px solid #473068;
  padding: 3px 10px 0px 10px;
  min-height: 50px;
  height: 100% !important;
  align-items: center;
}

.main-task {
  position: relative;
  vertical-align: top;
  padding-top: 25px;
  font-weight: bold;
  cursor: pointer;
  padding: 8px;
  display: flex;
  align-items: center;
}

.edit-container {
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  padding: 8px;
  display: flex-end;
  align-items: center;
}

.main-task::before {
  content: "";
  position: absolute;
  top: 0;
  left: 3%;
  width: 95%;
  border-top: 1px solid #ccc;
}

.second-col {
  margin-left: -25.8px;
  padding-left: 16px;
  background-color: $paginateBackground;
}

.md-card-content {
  padding: 25px;
  text-align: left;
  color: #473068;
}

.delete-icon {
  display: flex;
  margin-left: auto;
}

.notification-container {
  background: #ffffff;
  box-shadow: 0px 3px 6px rgba(71, 48, 104, 0.06);
  border-radius: 7px;
  margin-bottom: 20px;
  min-height: 70px;
}

.topics-container {
  box-shadow: 0px 3px 6px rgba(71, 48, 104, 0.06);
  border-radius: 7px;
  margin-bottom: 20px;
  background-color: #47c0be;
}

.sender-name {
  color: #ea7a66;
  font-size: 15px;
}

.notification-message {
  font-weight: 600;
  font-size: 16px;
  word-break: break-word;
}

.activity-title {
  font-weight: 600;
  font-size: 16px;
}

.main-task-name {
  font-size: 15px;
  margin: 0px 10px;
  text-align: left !important;
  font-weight: 1000 !important;
  flex-grow: 1;
  color: #473068;
}

.main-task .v-icon {
  margin-left: auto;
}

.selected-sub-task {
  background-color: $paginateBackground !important;
}

.date-style {
  font-weight: 500;
  color: #473068;
  font-size: 15px;
}

.chip-style {
  min-width: 70%;
  justify-content: center;
  height: 35px;
  display: flex;
  align-items: center;
}

.search-content {
  margin-bottom: 40px;
  padding: 12px;
}

.editable-icon {
  font-size: 20px;
  color: #5b3d87;
}

.subtask-textarea::v-deep {
  textarea {
    font-size: 14px;
    color: #5b3d87;
    line-height: 1.2;
  }
  .v-messages__message {
    margin-left: 0px !important;
  }
}

.inquiry-search-bar {
  text-align: right;
  align-items: center;
  justify-content: left;
  background: #ffffff;
  box-shadow: 0px 3px 6px rgba(71, 48, 104, 0.06);
  border-radius: 7px;
  height: 75px;
}

.description-child-container {
  text-align: left !important;
  max-width: 250px !important;
}

.activity-log-title {
  color: #ffffff;
  font-size: 15px;
  font-weight: 500;
}

.activity-log-title-container {
  background-color: #473068;
  color: #ffffff;
  justify-content: center;
  align-items: center;
  display: flex;
}

.icon-class {
  background-color: rgb(255 255 255 / 12%);
  border-radius: 29px;
  color: #442d65;
  cursor: pointer;
}

@media only screen and (max-width: 900px) {
  .status {
    height: auto;
  }
}

.list-container {
  display: flex;
  flex-direction: column;
}

.list-row {
  display: flex;
}

.list-data {
  list-style-type: none;
  padding: 0;
}

.main-task-item {
  margin-bottom: 8px;
  margin-right: 50px;
}

.toggle-icon {
  margin-left: auto;
  color: #473068;
}

.sub-task-list {
  list-style-type: none;
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0;
  position: relative;
}

.sub-task-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
}

.single-line-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  display: inline-block;
  width: 100%;
}
.sub-task {
  display: flex;
  align-items: center;
  flex-grow: 1;
  color: #473068 !important;
  font-weight: 600;
  width: 100%;
  height: 50px;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: space-between;
}

.subtask-textarea {
  flex-grow: 1;
  margin-right: 10px !important;
  color: #473068 !important;
  margin: 5px 0;
  overflow: hidden;
  max-height: 70px;
}

.subtask-textarea.scroll-enabled {
  overflow-y: auto;
}

.main-task-item .sub-task-item {
  height: auto;
  overflow: hidden;
}

.main-task-item .sub-task-item .v-textarea {
  height: auto;
}
.single-line-subtask {
  margin-bottom: -20px;
}

@media screen and (max-width: 768px) {
  .sub-task-item {
    margin-bottom: 5px;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .sub-task-item {
    margin-bottom: 8px;
  }
}

@media screen and (min-width: 1025px) {
  .sub-task-item {
    margin-bottom: 10px;
  }
}

.icon {
  color: #473068;
  font-size: 20px;
  cursor: pointer;
  margin-right: 10px;
}

.bin-icon {
  color: #a19da7;
  font-size: 20px;
  cursor: pointer;
}

.file-icon {
  display: block;
  color: #442d65;
  font-size: 20px;
  cursor: pointer;
  margin-right: 5px;
}

.file-count {
  margin-right: 5px;
}

.add-subtask-item {
  display: flex;
  align-items: center;
  margin-left: 25px;
  margin-top: 12px;
}

.md-card .notification-container {
  margin-bottom: 8px;
}
</style>
