<template>
  <div id="Bom" class="bom-container">
    <bom-component @change-tab="changeTab" />
    <div class="activity-log-container">
      <bom-log />
    </div>
  </div>
</template>

<script>
import BomLog from "../../components/Inquiry/BomLog.vue";
import BomComponent from "../../components/Inquiry/BomComponent.vue";
import { bomDataBus } from "../../utils/bomDataBus";

export default {
  name: "Bom",
  components: {
    BomComponent,
    BomLog,
  },
  methods: {
    changeTab(tab) {
      this.$emit("change-tab", tab);
    },
    forceUpdate() {
      bomDataBus.$emit("force-update");
    },
  },
  beforeRouteLeave(to, from, next) {
    this.forceUpdate();
    next();
  },
};
</script>

<style lang="scss" scoped>
.bom-container {
  background-color: #fff;
  padding-top: 10px;
  margin: 8px 40px;
  min-height: 50vh;
}

.activity-log-container {
  margin-top: 15px;
  background-color: #eef1fa;
  padding: 10px;
  width: 100%;
  box-shadow: unset;
}
</style>
