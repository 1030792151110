import { render, staticRenderFns } from "./BomCategoryItem.vue?vue&type=template&id=61ddc214&scoped=true"
import script from "./BomCategoryItem.vue?vue&type=script&lang=js"
export * from "./BomCategoryItem.vue?vue&type=script&lang=js"
import style0 from "./BomCategoryItem.vue?vue&type=style&index=0&id=61ddc214&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61ddc214",
  null
  
)

export default component.exports